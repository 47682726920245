<template>
<div>
    <section id="knowledge-base-content">
        <b-row>
            <b-col>
             <b-card no-body>
        <b-card-header class="pb-50">
            <h5>
                Filtreleme
            </h5>
        </b-card-header>
        <b-card-body>
            <b-row>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Hafta</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedWeekly" :options="weeklyList" class="w-100" :getOptionLabel="option => formattedDate(option.startAt)+' / '+formattedDate(option.endAt)" :reduce="val => val" />
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Sınıf/Ders</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedLesson" :options="lessons" class="w-100" :getOptionLabel="option => option.class.name+' / '+option.lesson.name" :reduce="val => val" />
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Alt Sınıf</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" multiple v-model="selectedClass" class="w-100" :options="childClass" :reduce="val => val.class.id" :getOptionLabel="option => option.class.name" />
                </b-col>
                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <b-button @click="saveWeeklyPlan()">Kaydet</b-button>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header class="pb-50">
                        <h5>Alt Ders Seçimi</h5>
                    </b-card-header>
                    <b-card-body class="scroll">
                        <b-list-group>
                            <b-list-group-item v-for="lesson in childLessons" :key="lesson.id" :active="selectedChildLesson == lesson" @click="selectedChildLesson = lesson">
                                {{ lesson.name }}
                            </b-list-group-item>
                        </b-list-group>
                        <b-overlay :show="loading" no-wrap> </b-overlay>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col>
                <b-card no-body>
                    <b-card-header class="pb-50">
                        <h5>Konu Seçimi</h5>
                    </b-card-header>
                    <b-card-body class="scroll">
                        <b-list-group>
                            <b-list-group-item v-for="topic in topics" :key="topic.id" :active="selectedTopic == topic" @click="selectedTopic = topic">
                                {{ topic.name }}
                            </b-list-group-item>
                        </b-list-group>
                        <b-overlay :show="loading" no-wrap> </b-overlay>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col>
                <b-card no-body>
                    <b-card-header class="pb-50">
                        <h5>Video Grup Seçimi</h5>
                    </b-card-header>
                    <b-card-body class="scroll">
                        <b-list-group-item v-for="topic in videoGroups" :key="topic.id" :active="selectedVideoGroup == topic" @click="selectedVideoGroup = topic">
                            {{ topic.name }}
                        </b-list-group-item>
                        <b-overlay :show="loading" no-wrap> </b-overlay>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col>
                <b-card no-body>
                    <b-card-header class="pb-50">
                        <h5>içerik Seçimi</h5>
                    </b-card-header>
                    <b-card-body class="scroll">
                        <b-list-group-item v-for="topic in elements" :key="topic.id" :active="checkElementSelected(topic)" @click="selectElement(topic)">
                            {{ topic.name }}
                        </b-list-group-item>
                        <b-overlay :show="loading" no-wrap> </b-overlay>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card no-body class="card-company-table">
                    <b-table :items="myPlans" responsive :fields="fields" class="mb-0">
                         <template #cell(lessonName)="data">
                            <b-button block variant="flat-primary" class="font-weight-bolder mb-12" @click="data.toggleDetails">{{data.item.lessonName }}</b-button>
                        </template>
                        <template #cell(date)="data">
                            <span>{{formattedDate(data.item.startAt)+" - "+ formattedDate(data.item.endAt) }}</span>
                        </template>
                         <template #cell(action)="data">
                            <b-button block variant="flat-danger" class="font-weight-bolder mb-12" @click="deleteWeeklyPlan(data.item.id)">Sil</b-button>
                        </template>
                        <template #row-details="data">
                            <b-card>
                                <b-card-body>
                                     <b-table :items="data.item.details" responsive :fields="childFields" class="mb-0">
                                        <template #cell(action)="data">
                                            <b-button block variant="flat-danger" class="font-weight-bolder mb-12" @click="deleteWeeklyPlanDetail(data.item.id)">Sil</b-button>
                                        </template>
                                     </b-table>
                                </b-card-body>
                            </b-card>
                        </template>
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
    </section>
</div>
</template>

<script>
import {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BTable,
    BBadge,
    BSidebar,
    BTooltip,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BProgress,
    BOverlay,
    BCardText,
    BListGroup,
    BListGroupItem,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import vSelect from "vue-select";
import SelectedStudent from "./PageComponent/SelectedStudent.vue";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import 'moment/locale/tr';
export default {
    components: {
        flatPickr,
        SelectedStudent,
        vSelect,
        AppCollapse,
        AppCollapseItem,
        ToastificationContent,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BCard,
        BTable,
        BBadge,
        BSidebar,
        BTooltip,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BProgress,
        BOverlay,
        BCardText,
        BListGroup,
        BListGroupItem,
    },
    data() {
        return {
            loading: false,
            weeklyList: [],
            selectedWeekly: null,
            selectedClass:null,
            lessons: [],
            selectedLesson:null,
            childClass:[],
            selectedClass:null,
            childLessons: [],
            selectedChildLesson: null,
            topics: [],
            selectedTopic: null,
            videoGroups: [],
            selectedVideoGroup: null,
            elements:[],
            selectedElements:[],
             fields: [
                { key: 'date', label: 'Hafta' },
                { key: 'lessonName', label: 'Ders' },
                { key: 'childLessonName', label: 'Alt Ders' },
                { key: 'topicName', label: 'Konu' },
                { key: 'videoGroupName', label: 'Video Grup' },
                { key:'action', label:'İşlemler'}
            ],
            childFields:[
                { key: 'className', label: 'Sınıf' },
                { key: 'elementType', label: 'Ödev Tipi' },
                { key: 'elementName', label: 'Ödev' },
                {key:'isSended',label:'Durumu'},
                {key:'sendAt',label:'Gönderme Tarihi'},
                { key:'action', label:'İşlemler'}
            ],
            myPlans:[]
        };
    },
    created() {
        this.getMyPlans();
        this.getWeekly();
        this.getLesson();
    },
    watch: {
        selectedLesson: function () {
            this.getChildClass();
            this.getChildLesson();
        },
        selectedChildLesson: function () {
            this.getTopics();
        },
        selectedTopic: function () {
            this.getVideoGroup();
        },
        selectedVideoGroup: function () {
            this.getElements();
        },
    },
    methods: {
        getChildClass(){
            this.childClass=this.selectedLesson.childClass;
        },
        async getMyPlans(){
            this.loading = true;
            var plans = await this.$http.get("Report/MyWeeklyPlan");
            this.myPlans = plans.data.data;
            this.loading = false;
        },
        async saveWeeklyPlan(){
            this.loading =true;
            for (let index = 0; index < this.selectedElements.length; index++) {
                const element = this.selectedElements[index];

                const request = {
                    year:this.selectedWeekly.year,
                    week:this.selectedWeekly.week,
                    lessonId:this.selectedLesson.lesson.id,
                    childLessonId:this.selectedChildLesson.id,
                    topicId:this.selectedTopic.id,
                    videoGroupId:this.selectedVideoGroup.id,
                    elementType:element.type,
                    elementId:element.referanceId,
                    classIds:this.selectedClass
                };

               await this.$http.post("Report/MyWeeklyPlan",request);
            }
           this.getMyPlans();
        },
        async deleteWeeklyPlan(planId){
            this.loading = true;
            await this.$http.delete("Report/MyWeeklyPlan/"+planId);
            this.getMyPlans();
            this.loading = false;
        },
        async deleteWeeklyPlanDetail(detailId){
            this.loading = true;
            await this.$http.delete("Report/MyWeeklyPlanDetail/"+detailId);
            this.getMyPlans();
            this.loading = false;
        },
        async getWeekly() {
            this.loading = true;
            var weekly = await this.$http.get("Report/WeekList?IsNext=true");
            this.weeklyList = weekly.data.data;
            this.loading = false;
        },
        async getLesson() {
            this.loading = true;
            var lesson = await this.$http.get("Teacher/Lessons");
            this.lessons = lesson.data.data;
            this.loading = false;
        },

        async getChildLesson() {
            this.loading = true;
            this.selectedChildLesson = null;
            this.selectedTopic =null;
            this.topics=[];
            this.selectedVideoGroup = null;
            this.videoGroups =[];
            this.selectedElements=[];
            this.elements=[];
            var data = this.lessons.filter((x) => x == this.selectedLesson);
            if (data.length > 0) {
                data = data[0];
                var childLesson = await this.$http.get(
                    "Teacher/ChildLessons/" + data.lesson.id + "/" + data.class.id
                );
                this.childLessons = childLesson.data.data;
            }
            this.loading = false;
        },

        async getTopics() {
            this.loading = true;
            this.selectedTopic =null;
            this.topics=[];
            this.selectedVideoGroup = null;
            this.videoGroups =[];
            this.selectedElements=[];
            this.elements=[];
            var topics = await this.$http.get(
                "Teacher/Topics/" + this.selectedChildLesson?.id
            );
            this.topics = topics.data.data;
            this.loading = false;
        },

        async getVideoGroup() {
            this.loading = true;
            this.selectedVideoGroup = null;
            this.videoGroups =[];
            this.selectedElements=[];
            this.elements=[];
            var videoGroups = await this.$http.get(
                "Teacher/VideoGroup/" + this.selectedTopic?.id
            );
            this.videoGroups = videoGroups.data.data;
            this.loading = false;
        },

        async getElements(){
            this.loading = true;
            this.selectedElements=[];
            this.elements=[];
            var tests = await this.$http.get(
                "Teacher/Priview/" + this.selectedVideoGroup?.id
            );
            this.elements = tests.data.data;
            this.elements = this.elements.filter(x=>x.type != 'Document');
            this.loading = false;
        },
        checkElementSelected(element){
            return this.selectedElements.find(x=>x.referanceId == element.referanceId) != null;
        },
        selectElement(element){
            const checkElement = this.selectedElements.find(x=>x.referanceId == element.referanceId);
            if(checkElement){
                this.selectedElements = this.selectedElements.filter(x=>x.referanceId != element.referanceId);
            }else{
                this.selectedElements.push(element);
            }
        },
        formattedDate(dateString) {
            return moment(dateString, 'DD.MM.YYYY').format('DD MMMM YYYY');
        }
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style><style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";
@import "@core/scss/vue/pages/page-knowledge-base.scss";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}

.scroll {
    max-height: 500px;
    overflow-y: auto;
}
</style>
